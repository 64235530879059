import { template as template_244c371ed2a7491f8204c22cb38db3de } from "@ember/template-compiler";
const WelcomeHeader = template_244c371ed2a7491f8204c22cb38db3de(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
