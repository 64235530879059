import { template as template_c7b917bbb2ff4f6d9aba2e5bd3ee49d5 } from "@ember/template-compiler";
const FKLabel = template_c7b917bbb2ff4f6d9aba2e5bd3ee49d5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
